import { Character } from "./";

export class Player {
  constructor(username) {
    this.username = username;
    this.characters = [];
    this.activeCount = 0;
  }

  addCharacter(character) {
    this.characters.push(character);
  }

  addCharacterString(str) {
    str.split(",")
      .map(ch => ch.trim())
      .sort(() => Math.random() - 0.5)
      .forEach(ch => { if (Boolean(ch)) this.characters.push(new Character(ch))});
  }

  updateActiveCount() {
    if (this.activeCount < this.characters.length) {
      this.activeCount = this.activeCount + 1;
    } 
    else {
      this.softReset();
    }
  }

  getNextFighter() {
    if (
      0 < this.characters.length
      && this.activeCount < this.characters.length
    ) {
      const fighter = this.characters[this.activeCount];
      this.updateActiveCount();
      return {
        player: this, 
        character: fighter,
      };
    }    
    // return false;
  }

  get availableFighterCount() {
    return this.count - this.activeCount;
  }

  softReset() {
    this.activeCount = 0;
  }

  updateCharacters(title) {
    this.characters = this.characters.filter(c => c.title !== title);
  }

  get count() {
    return this.characters.length;
  }
}
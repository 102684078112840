import React from "react";
import styled from "styled-components";

const Location = ({ title, subtitle }) => {
    return (
        <Wrap>
            <h1>{title}</h1>
            <hr/>
            <h3>{subtitle}</h3>
        </Wrap>
    );
}

const Wrap = styled.header`
  margin: 32px auto;
  width: max-content;
  text-align: center;
`;

export default Location;
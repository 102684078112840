import styled from "styled-components";

export const Button = styled.button`
  margin-top: 8px;
  padding: 8px;
  border: 2px solid #333;
  border-radius: 4px;
  color: #333;

  &:hover {
    border: 2px solid #2FF924;
  }

  &:focus {
    border: 2px solid #2FF924;
  }

  &:disabled {
    background-color: #DDD;
    border-color: #AAA;
    color: #AAA;
    cursor: initial;
  }
`;
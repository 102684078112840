import React from "react";
import styled from "styled-components";

const Image = styled.img`
    height: 50%;
`;

export const SmashImage = ({ name, src }) => {
    if (src) {
        return <Image src={src} alt={name} />;
    }
    const url = "http://smashbros-ultimate.com/images/char/";
    // if (name.toLowerCase() === "banjo & kazooie") return `${url}banjo-and-kazooie.png`;
    const urlSrc = url + name.replaceAll(" ", "_").toLowerCase() + ".png";
    return <Image src={urlSrc} alt={name} />
}
import React from "react";
import styled from "styled-components";
import { SmashImage } from "./SmashImage";
import { smash } from "../data";

// const MatchWrap = styled.div`
//   display: flex;
//   padding: 8px;
// `;

// const InfoBtn = styled.button`
//   padding: 8px;
//   border-radius: 4px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

const Versus = styled.div`
  position: absolute;
  top: calc((var(--polygon-size) / 2) - 30px);
  left: calc((var(--polygon-size) / 2) - 30px);
  z-index: 1;
`;

const Bye = styled.div`
  position: absolute;
  bottom: 0;
  right: 8px;
  z-index: 1;
`;

const Header1 = styled.h1`
  padding: 8px;
  line-height: normal;
  color: #eee;
`;

// const Header2 = styled.h2`
//   padding: 0 8px;
//   color: #eee;
// `;

const Header3 = styled.h3`
  padding: 0 8px;
  color: #bbb;
`;

const PolygonBase = styled.button`
  width: var(--polygon-size);
  height: var(--polygon-size);
  background-color: aquamarine;
`;

const LeftPolygon = styled(PolygonBase)`
  clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
  background-color: purple;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
`;

const RightPolygon = styled(PolygonBase)`
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;
`;

const PolygonWrap = styled.div`
  --polygon-size: 250px;
  margin: 16px;
  border-radius: 8px;
  position: relative;
  background-color: #333;
  overflow: hidden;
  box-shadow: var(--SHADOW-FULL);
`;

// const Image = styled.img`
//   height: 50%;
// `;

// const getSmashImage = (name) => {
//   const url = "http://smashbros-ultimate.com/images/char/";
//   if (name === "Banjo & Kazooie") return `${url}banjo-and-kazooie.png`;
//   // return url + name.replaceAll(" ", "_").toLowerCase() + ".png";
//     return <Image src={url + name.replaceAll(" ", "_").toLowerCase() + ".png"} alt={name} />
// }

export function Match({ match, index, losers, setLosers }) {
    // console.log("args", match, index, losers, setLosers);
    const [winner, setWinner] = React.useState(null);
    
    React.useLayoutEffect(() => {
        if (!losers[index] && !match.bye) {
            setWinner(null);
        }
        // if (match.bye) {
        //   setWinner(match.slot1);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [losers]);
    
    const getBgColor = (obj) => {
        if (winner && winner === obj) return "green";
        if (losers[index] && losers[index] === obj) return "red";
        if (match.bye) return "yellow";
        return "gray";
    }
    
    const title1 = match?.slot1?.character?.title;
    const title2 = match?.slot2?.character?.title;
    
    return (
        <PolygonWrap>
            <LeftPolygon
                onClick={() => {
                    setWinner(match.slot1);
                    setLosers({...losers, [index]: match.slot2});
                }}
                style={{ backgroundColor: getBgColor(match.slot1) }}
            >
                {/* <Header2>{match.slot1.character.title}</Header2> */}
                <SmashImage src={smash[title1]} name={title1} />
                <Header3>{match.slot1.player.username}</Header3>
            </LeftPolygon>
            {
                !match.bye
                && (
                    <>
                        <Versus><Header1>VS</Header1></Versus>
                        <RightPolygon
                            onClick={() => {
                                setWinner(match.slot2);
                                setLosers({...losers, [index]: match.slot1});
                            }}
                            style={{ backgroundColor: getBgColor(match.slot2) }}
                        >
                            <Header3>{match.slot2.player.username}</Header3>
                            <SmashImage src={smash[title2]} name={title2} />
                            {/* <Header2>{match.slot2.character.title}</Header2> */}
                        </RightPolygon>
                    </>
                )
            }
            {
                match.bye
                && <Bye><Header1>BYE</Header1></Bye>
            }
        </PolygonWrap>
    )
}

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Home from "./components/Home";
import Join from "./components/Join";
import Memes from "./components/Memes";
import { Draft, QuickTourney } from "./components/Smash/components";

function App() {
    return (
        <BrowserRouter>
            <GlobalStyles />
            <Layout>
                <Routes>
                    <Route path="/draft" element={<Draft />} />
                    <Route path="/join" element={<Join/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/smash" element={<QuickTourney/>}/>
                    <Route path="/memes" element={<Memes/>}/>
                    <Route path="/*" element={<Home/>}/>
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
import React from "react";
import styled from "styled-components";
import { Button } from "../styles";
import { Match } from "./";

const MatchesWrap = styled.div`
  max-width: 100%;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

const NextRound = styled(Button)`
  background-color: white;
  margin-bottom: 16px;
`;

export function Rounds({ tournament }) {
    const [losers, setLosers] = React.useState({});
    
    const handleNextRoundClick = () => {
        Object.values(losers).forEach(loser => {
            console.log(loser?.player.username);
            loser?.player.updateCharacters(loser?.character.title);
        });
        tournament.matchRandomizer();
        setLosers({});
    }
    
    return (
        <>
            {
                tournament.currentMatches.length === 1
                && tournament.currentMatches[0]["bye"]
                && <h1>WINNER!</h1>
            }
            <MatchesWrap>
                {
                    tournament.currentMatches?.map((match, i) => {
                        return <Match match={match} key={i} index={i} losers={losers} setLosers={setLosers}/>
                    })
                }
            </MatchesWrap>
            <NextRound
                disabled={!(Object.values(losers).length === tournament.currentMatches.length)}
                onClick={handleNextRoundClick}
            >Next Round</NextRound>
        </>
    );
}
import React from "react";
import styled from "styled-components";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

const TrueFalseWrap = styled.div`
    display: flex;
  
    & > *:not(:first-child) {
        margin-left: 8px;
    }
`;
const TrueFalseLabel = styled.label`
    padding: 8px;
    background-color: var(--grey);
    border-radius: 8px;
    outline: 2px solid var(--dark);
    color: var(--dark);
    font-weight: 700;
`;
const SvgBtn = styled.button.attrs({ type: "button" })`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  
    svg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: ${({ state }) => state ? "white" : "unset"};
        fill: ${({ color, state }) => state ? color : "var(--grey)"};
    }
`;

const TrueFalse = ({ label, onClick }) => {
    const [state, setState] = React.useState(false);
    
    const handleClick = (e) => {
        onClick(e);
        setState(!state);
    }
    
    return (
        <TrueFalseWrap>
            <TrueFalseLabel>{label}</TrueFalseLabel>
            <SvgBtn
                color="green"
                onClick={handleClick}
                state={state}
                value="true"
            >
                <AiFillCheckCircle/>
            </SvgBtn>
            <SvgBtn
                color="red"
                onClick={handleClick}
                state={!state}
                value="false"
            >
                <AiFillCloseCircle/>
            </SvgBtn>
        </TrueFalseWrap>
    );
}

export default TrueFalse;
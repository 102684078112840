import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {Squash as Hamburger} from "hamburger-react";

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;

  & > hr {
    width: 94%;
    margin: 0 auto;
  }

  & > div:first-of-type {
    //border-radius: 0 16px 16px 0;
    border-radius: 8px;
    background-color: var(--dark);
    color: var(--light);
    position: fixed !important;
    bottom: 1vh;
    right: 1vh;
  }
`;
// const Header = styled.div`
//   width: 100%;
//   min-height: 50px;
//   //background-color: var(--dark);
//   //box-shadow: var(--shadow-elevation-medium);
//   //position: fixed;
//   //top: 0;
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
// `;
// const Error = styled(Header)`
//   top: 50px;
//   background-color: var(--color);
//   color: var(--light);
// `;
// const Footer = styled(Header)`
//   top: unset;
//   bottom: 0;
// `;
const HomeAnchor = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    //padding-bottom: 12px;
    //border-bottom: solid 4px var(--color);
    transform: scale(1.2) rotate(-90deg);
    transition: all 0.6s;
  }
`;
const AwayAnchor = styled(HomeAnchor).attrs({
  target: "_blank",
  rel: "noreferrer",
})``;
const TBD = styled.div` // TODO: update values for this so it doesn't go too far down the screen
    --size: calc(100vh - (48px + 4vh));
    --size: 67vh;
    width: var(--size);
    height: 100vw;
    background-color: var(--dark);
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    top: ${({ isOpen }) => isOpen ? "0" : "calc(8px - var(--size))"};
    left: 0;
    transition: all 1s ease-in-out;
    z-index: 100;
    transform: rotate(90deg);
    transform-origin: 50vw 50%;

    //& > div:first-of-type {
    //    border-radius: 0 16px 16px 0;
    //    background-color: var(--dark);
    //    color: var(--light);
    //    position: absolute !important;
    //    top: 0;
    //    right: -44px;
    //    z-index: -1;
    //}
`;
const Color = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    background-color: ${({ color }) => color ?? "var(--color)"};
    // color: ${({ color }) => color === "yellow" ? "var(--dark)" : "var(--light)"};
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    & > * {
        padding: 0 6vh;
        //border-right: solid 2px ${({ color }) => color === "yellow" ? "var(--dark)" : "var(--light)"};
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
`;

export const Layout = ({ children }) => {
  // const [error, setError] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Wrap>
        <Hamburger toggled={isOpen} toggle={() => setIsOpen(!isOpen)}/>
        <TBD isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <Color color="rgb(255, 127, 127)" isOpen={isOpen}>
              <HomeAnchor as={Link} to="/coming-soon">Dan</HomeAnchor>
              <HomeAnchor as={Link} to="/">home</HomeAnchor>
          </Color>
          <Color color="rgb(255, 212, 127)" isOpen={isOpen}>
              <HomeAnchor as={Link} to="/coming-soon">Gage</HomeAnchor>
              <HomeAnchor as={Link} to="/join">join</HomeAnchor>
          </Color>
          <Color color="rgb(255, 255, 127)" isOpen={isOpen}>
              <AwayAnchor href="https://matthenningsen.com">Matt</AwayAnchor>
              <HomeAnchor as={Link} to="/login">login</HomeAnchor>
          </Color>
          <Color color="rgb(127, 255, 212)" isOpen={isOpen}>
              <AwayAnchor href="https://josephcorrado.com">Joe</AwayAnchor>
              <HomeAnchor as={Link} to="/memes">memes</HomeAnchor>
          </Color>
          <Color color="rgb(127, 127, 255)" isOpen={isOpen}>
              <AwayAnchor href="https://johncavaseno.com">John</AwayAnchor>
              <HomeAnchor as={Link} to="/smash">smash</HomeAnchor>
          </Color>
          <Color color="rgb(212, 127, 255)" isOpen={isOpen}>
              <AwayAnchor href="https://nicholasbouzas.com">Nick</AwayAnchor>
              <HomeAnchor as={Link} to="/draft">draft</HomeAnchor>
          </Color>
      </TBD>
      {/* {error && <Error>{error}</Error>} */}
      {children}
      {/* <Footer>footer</Footer> */}
    </Wrap>
  )
}

import React from "react";
import styled from "styled-components";
import { Player, Tournament } from "../classes";
import { AddCharacters, Rounds, SmashImage } from "./index";
// import { useNavigate } from "react-router-dom";
import { smash } from "../data";

const Layout = styled.div`
    height: 100vh;
    padding: 16px;
    display: flex;
  
    button:not([type="button"]) {
        padding: 1px 4px;
        border: 2px solid var(--dark);
    }
    button:hover {
        background-color: green;
        color: white;
    }
`;

const Left = styled.div`
    height: 100%;
    flex: 1;
    flex-basis: 30%;
    //background-color: aquamarine;
`;

const Right = styled.div`
    height: 100%;
    flex: 1;
    flex-basis: 70%;
    //background-color: pink;
    overflow-y: scroll;
`;

const WrapSmashList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
`;

const WrapSmashImage = styled.div`
    width: 120px;
    height: 120px;
    margin: 8px;
    padding: 8px;
  
    & > img {
      height: 100%;
    }
    
    ${props => props.selected && `
        border: 2px solid red;
        background:
             linear-gradient(to top left,
                 rgba(0,0,0,0) 0%,
                 rgba(0,0,0,0) calc(50% - 0.8px),
                 red 50%,
                 rgba(0,0,0,0) calc(50% + 0.8px),
                 rgba(0,0,0,0) 100%),
             linear-gradient(to top right,
                 rgba(0,0,0,0) 0%,
                 rgba(0,0,0,0) calc(50% - 0.8px),
                 red 50%,
                 rgba(0,0,0,0) calc(50% + 0.8px),
                 rgba(0,0,0,0) 100%);
        & > img {
            filter: grayscale(100%);
        }
    `}
`;

const WrapTourney = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Draft = () => {
    // const navigate = useNavigate();
    const [players, setPlayers] = React.useState([]);
    const [playerName, setPlayerName] = React.useState("");
    const [tournament, setTournament] = React.useState(null);
    const [chosenCharacters, setChosenCharacters] = React.useState([]);
    
    const handleCharacterUpdate = (character) => {
        if (!chosenCharacters.includes(character)) {
            setChosenCharacters([...chosenCharacters, character]);
        }
        else {
            setChosenCharacters(chosenCharacters.filter(c => c !== character));
        }
    }
    
    const handlePlayerKeyup = e => {
        if (e.key === "Enter") {
            handleAddPlayer();
        }
    }
    
    const handleAddPlayer = () => {
        players.push(new Player(playerName));
        setPlayers([...players]);
        setPlayerName("");
    };
    
    const handleStartDraft = () => {
        // navigate("/smash", { state: { players } });
        const newTourney = new Tournament(players.length);
        players.forEach(player => newTourney.addParticipant(player));
        newTourney.matchRandomizer();
        setTournament(newTourney);
    }
    
    if (tournament) {
        return <WrapTourney><Rounds tournament={tournament} /></WrapTourney>;
    }
    
    return (
        <Layout>
            <Left>
                <h2>Players</h2>
                <input
                    type="text"
                    placeholder="Player name"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    onKeyUp={handlePlayerKeyup}
                />
                <button onClick={handleAddPlayer}>Add</button>
                {players.map((player, index) => (
                    <AddCharacters
                        key={index}
                        player={player}
                        onUpdate={handleCharacterUpdate}
                        chosenCharacters={chosenCharacters}
                    />
                ))}
                <br />
                <button
                    onClick={handleStartDraft}
                    disabled={!(players.length >= 2 && chosenCharacters.length >= 2)}
                >
                    Start Tournament
                </button>
            </Left>
            <Right>
                <h2>Characters</h2>
                <WrapSmashList>
                    {Object.keys(smash).map((key, index) => (
                        <WrapSmashImage
                            key={index}
                            onClick={() => alert(key)}
                            selected={chosenCharacters.includes(key)}
                        >
                            <SmashImage name={key} src={smash[key]} />
                        </WrapSmashImage>
                    ))}
                </WrapSmashList>
            </Right>
    </Layout>
  );
}
import { Match } from "./";

export class Tournament {
  constructor(participantCount) {
    this.participantCount = participantCount;
    this.participants = [];
    this.matches = [];
    this.noOfRound = 0;
  }

  addParticipant(player) {
    this.participants.push(player);
  }

  get isFull() {
    return this.participantCount === this.participants.length;
  }

  getRandomCharacter(username) {
    let fighter = null;

    while (!fighter) {
      const randomIndex = Math.floor(Math.random() * this.participants.length);
      const randomParticipant = this.participants[randomIndex];
      if (
        username !== randomParticipant.username
        && randomParticipant.availableFighterCount
      ) {
        fighter = randomParticipant.getNextFighter();
      }
    }

    return fighter;
  }

  getLeader() { // most overall when used with activeCounts reset, after that gets most available
    let leader = this.participants[0];
    let mostAvailable = leader.availableFighterCount;
    let marker = 1;

    while (marker < this.participants.length) {
      const participant = this.participants[marker];
      if (mostAvailable === participant.availableFighterCount) {
        if (Math.floor(Math.random() * 2)) { // basically random true or false
          leader = participant;
          mostAvailable = participant.availableFighterCount;
        }
      }
      else if (mostAvailable < participant.availableFighterCount) {
        leader = participant;
        mostAvailable = participant.availableFighterCount;
      } 
      marker += 1;
    }

    return leader;
  }

  getCharacterCount() {
    return this.participants.reduce((prev, curr) => prev + curr.count, 0);
  }

  resetActiveCounts() {
    this.participants.forEach(p => p.softReset());
  }

  // getLeftovers() {
  //   return this.participants.reduce((prev, curr) => {
  //     while (curr.availableFighterCount) {
  //       prev.push(new Match(curr.getNextFighter()));
  //     }
  //   }, []);
  // }

  noOfParticipantsWithFightersLeft() {
    return this.participants.reduce((prev, curr) => {
      if (curr.availableFighterCount) {
        return prev + 1;
      }
      return prev;
    }, 0);
  }

  matchRandomizer() {
    const matches = [];
    // this.resetActiveCounts();  // idk if we need both yet
    let leader = this.getLeader();

    if (this.getCharacterCount() % 2 === 1) { // if odd characters, set up BYE
      matches.push(new Match(leader.getNextFighter()));
    }

    let noOfParticipantsWithFightersLeft = this.noOfParticipantsWithFightersLeft();
    while (noOfParticipantsWithFightersLeft) {
      leader = this.getLeader();

      if (noOfParticipantsWithFightersLeft > 1) {
        matches.push(new Match(
          leader.getNextFighter(),
          this.getRandomCharacter(leader.username)
        ));
      }
      else {
        matches.push(new Match(leader.getNextFighter()));
      }

      noOfParticipantsWithFightersLeft = this.noOfParticipantsWithFightersLeft();
    }

    this.matches.push(matches);
    this.resetActiveCounts(); // idk if we need both yet
  }

  get currentMatches() {
    return this.matches[this.matches.length - 1];
  }

  get champion() {
    if (this.noOfParticipantsWithFightersLeft() === 1) {
      // this.resetActiveCounts();
      return this.getLeader().username;
    }
    return false;
  }
}
import React from "react";
import styled from "styled-components";
import Location from "../Location";
import { Input, Textarea, TrueFalse } from "../FormInputs";
import { Btn, Btns, Card, Form } from "../Shared";
import { AiFillCloseCircle } from "react-icons/ai";

const defaultData = [
  {
    location: "Henn's Den",
    meme: "You swimming there Joe?",
    memer: "John",
    onDancation: false,
    tags: ['smash', 'covid', 'joe'],
  },
  {
    location: "Fort Lauderdale",
    meme: "Cause everyone knows that ducks disassociate at the beach.",
    memer: "Gage",
    onDancation: true,
    tags: ['ducks', 'high', 'florida'],
  },
  {
    location: "Oneonta",
    meme: "Krabby Patty Specret Formula! Specret?",
    memer: "Dan",
    onDancation: true,
    tags: ['specret', 'plankton', 'spongebob'],
  },
];

export const Memes = () => {
  // eslint-disable-next-line no-unused-vars
  const [memes, setMemes] = React.useState(defaultData);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = React.useState(null);
  const [form, setForm] = React.useState({});
  const [tags, setTags] = React.useState([]);

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });
  const handleDanClick = (e) => {
    setForm({ ...form, onDancation: !form.onDancation });
  }
  const handleTagsKeyUp = (e) => {
    if (e.key === "Enter") {
      if (!tags.find((tag) => tag.toLowerCase() === e.target.value))
        setTags([...tags, e.target.value.toLowerCase()]);
      e.target.value = "";
    }
  }
  const handleClickX = (e) => {
    const { textContent } = e.target.parentElement?.parentElement?.parentElement;
    setTags(tags.reduce((prev, curr) => {
      if (curr !== textContent) prev.push(curr);
      return prev;
    }, []))
  }
  const handleSubmit = async (e) => {
    return alert("Not implemented yet");
    // const response = await fetch("/api/memes/create", {
    //   method: "POST",
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     meme,
    //     memer,
    //     location,
    //     onDancation,
    //     tags,
    //   }),
    // })
    //
    // const json = await response.json();
    // console.log(json);
    // if (response.status !== 200) {
    //   // setError(json);
    // }
    // else {
    //   window.dan = json;
    //   // navigate("/login");
    // }
  }
  
  // React.useEffect(() => {
  //   const getData = async () => {
  //     const response = await fetch("/api/memes", {
  //       method: "POST",
  //     })
  //
  //     const json = await response.json();
  //     if (response.status !== 200) {
  //       setError(json);
  //     }
  //     else {
  //       const dataArray = json.map((obj) => obj.data);
  //       console.log(dataArray);
  //       setMemes(dataArray);
  //     }
  //   }
  //   getData();
  // }, []);
  
  React.useEffect(() => {
    setForm({});
  }, [error]);

  return (
    <Wrap>
      <Location title="The Meme Diary" subtitle="Where dreams become reality"/>
      <Card>
        <Form>
          <Textarea
              name="Meme"
              onChange={handleChange}
          />
          <Input
              name="Memer"
              onChange={handleChange}
          />
          <Input
              name="Location"
              onChange={handleChange}
          />
          <TrueFalse label="onDancation" onClick={handleDanClick}/>
          <Input
              name="Tags"
              onKeyUp={handleTagsKeyUp}
              onClick={e => e.preventDefault()}
              onChange={e => e.preventDefault()}
          />
          <Tags>{tags.map((tag, i) => (
              <Tag key={i}>
                {tag}
                <SvgBtn onClick={handleClickX}>
                  <AiFillCloseCircle/>
                </SvgBtn>
              </Tag>
          ))}</Tags>
        <Btns>
            <Btn onClick={handleSubmit} value="Submit" disabled/>
        </Btns>
        </Form>
    </Card>
      {
        memes.length &&
        memes.map((data, index) => {
          return (
            <Card padding="16px" key={index}>
              <p>Meme: "{data.meme}"</p>
              <p>Memer: {data.memer}</p>
              <p>Location: {data.location}</p>
              <p>On Dancation: {data.onDancation.toString()}</p>
            </Card>
          )
        })
      }
    </Wrap>
  )
}

const Wrap = styled.div`
  & > div {
    margin-bottom: 32px;
  }
`;
const Tags = styled.div`
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
  
    & > * {
        margin-top: 16px;
        margin-right: 16px;
    }
`;
const Tag = styled.span`
  height: 24px;
  padding: 4px;
  padding-left: 8px;
  background-color: var(--light);
  border-radius: 16px;
  outline: 2px solid var(--dark);
  color: var(--dark);
  font-weight: 700;
  line-height: 12px;
  display: flex;
  
  & > button {
    margin-left: 8px;
  }
`;
const SvgBtn = styled.button.attrs({ type: "button" })`
    border-radius: 50%;
  
    svg {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: red;
        fill: white;
    }
`;
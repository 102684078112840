import { createGlobalStyle } from "styled-components"
import "@fontsource/eb-garamond";
import "@fontsource/macondo";

const developer = "Joseph 'Hot Sauce' Corrado";
window.developer = developer;

const GlobalStyles = createGlobalStyle`
  /* Josh Comeau's global styles */
  /* 1. Use a more-intuitive box-sizing model. */
  *, *::before, *::after {
    box-sizing: border-box;
  }
  /* 2. Remove default margin for common elements */
  body, h1, h2, h3, h4, h5, h6, p, figure, blockquote, ul, ol, dl, dt, dd {
    margin: 0;
  }
  /* 3. Allow percentage-based heights in the application */
  html, body {
    height: 100%
  }
  /* 4. Improve the typography across the site. */
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  /* 5. Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }
  /* 6. Inherit fonts for inputs and buttons */
  input, button, textarea, select {
    font: inherit;
  }
  /* 7. Create a root stacking context */
  #__next {
    isolation: isolate;
  }

  /* site-specific global stylings */
  :root {
      /* colors */
      --color: aquamarine;
      --light: #eee;
      --dark: #222;
      --grey: gainsboro;
    
      /* shadows */
      --shadow-color: 0deg 0% 59%;
      --shadow-elevation-low:
        0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.3),
        0.4px 0.9px 1.2px -1px hsl(var(--shadow-color) / 0.35),
        1px 2.1px 2.8px -2px hsl(var(--shadow-color) / 0.4);
      --shadow-elevation-medium:
        0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.32),
        0.9px 1.9px 2.5px -0.7px hsl(var(--shadow-color) / 0.36),
        2.2px 4.5px 6px -1.3px hsl(var(--shadow-color) / 0.4),
        5.3px 10.5px 14.1px -2px hsl(var(--shadow-color) / 0.44);
      --shadow-elevation-high:
        0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
        2.1px 4.2px 5.6px -0.3px hsl(var(--shadow-color) / 0.36),
        4px 8px 10.7px -0.7px hsl(var(--shadow-color) / 0.39),
        6.7px 13.4px 18px -1px hsl(var(--shadow-color) / 0.41),
        11px 22.1px 29.6px -1.3px hsl(var(--shadow-color) / 0.43),
        17.7px 35.4px 47.5px -1.7px hsl(var(--shadow-color) / 0.46),
        27.5px 55px 73.8px -2px hsl(var(--shadow-color) / 0.48);
    
      /* fonts */
      --font: "EB Garamond", serif;
      --font-alt: "Macondo", cursive;
  }
  
  body {
    font-family: "EB Garamond", serif;

    a, h2, h3, h4, h5, h6, [type="button"], [type="submit"] {
      font-family: "Macondo", cursive;
    }
  }

  /* button reset */
  button {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    cursor: pointer;
    color: black;
  }

  /* focus-visible */
  *:focus-visible {
    outline: unset;
    outline: 2px solid var(--dark);
  }
`

export default GlobalStyles

import React from "react";
import styled from "styled-components";

const TaWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
const Label = styled.label`
    width: 100%;
    padding: 8px;
    border-radius: 8px 8px 0 0;
    font-size: 1rem;
    font-weight: 700;
    outline: solid 2px var(--dark);
    background-color: var(--grey);
    color: var(--dark);
`;
const Textarea = styled.textarea`
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    padding: 8px;
    border: none;
    border-radius: 0 0 8px 8px;
    font-size: 14px;
    color: var(--dark);
`;

const LabelledTextarea = ({
    name,
    onChange,
    ...props
}) => {
    const [value, setValue] = React.useState("");
    
    const handleChange = (e) => {
        setValue(e.target.value);
        onChange && onChange(e.target.value);
    }
    
    return (
        <TaWrap>
            <Label htmlFor={name}>{name}</Label>
            <Textarea
                name={name}
                onChange={handleChange}
                value={value}
                {...props}
            />
        </TaWrap>
    );
};

export default LabelledTextarea;
import React from "react";
import { useNavigate } from "react-router-dom";
import Location from "../Location";
import { Input } from "../FormInputs";
import { Btn, Btns, Card, Form } from "../Shared";

const Login = () => {
    const navigate = useNavigate();
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState({});
    
    const isDisabled = () => {
        return !form.email || !form.password;
    };
    
    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });
    const handleCancel = () => navigate("/");
    const handleLogin = async () => {
        // return alert("Not implemented yet");
        const response = await fetch("/api/user/login", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form),
        });
        
        const json = await response.json();
        if (response.status !== 200) {
            setError(json);
        }
        else {
            window.dan = json;
            navigate("/welcome");
        }
    }
    
    React.useEffect(() => {
        setForm({});
    }, [error])
    
    return (
        <>
            <Location title="Login Page" subtitle="All the world's a stage"/>
            <Card>
                <Form>
                    <Input
                        onChange={handleChange}
                        name="email"
                    />
                    <Input
                        onChange={handleChange}
                        name="password"
                        type="password"
                    />
                    <Btns>
                        <Btn
                            value="login"
                            onClick={handleLogin}
                            disabled={isDisabled()}
                        />
                        <Btn
                            value="later dude"
                            onClick={handleCancel}
                            // disabled={isDisabled()}
                        />
                    </Btns>
                </Form>
            </Card>
        </>
  )
}

export default Login;
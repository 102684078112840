import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
`;
const Input = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 0 8px 8px 0;
  border: none;
  font-size: 1rem;
  font-weight: 700;
`;
const Label = styled(Input).attrs({
  as: "label",
})`
  border-radius: 8px 0 0 8px;
  outline: solid 2px var(--dark);
  background-color: var(--grey);
  color: var(--dark);
  flex: 0;
`;

const LabelledInput = ({
    name,
    ...props
}) => {
    return (
        <Wrap>
            <Label htmlFor={name}>{name}</Label>
            <Input
                name={name}
                {...props}
            />
        </Wrap>
    )
}

export default LabelledInput;

import React from "react";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { smash } from "../data";

const SvgBtn = styled.button.attrs({ type: "button" })`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    vertical-align: sub;
  
    svg {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: red;
        fill: white;
    }
`;

const Wrap = styled.div`
    margin: 16px;
`;

export const AddCharacters = ({ player, onUpdate, chosenCharacters }) => {
    const [characterName, setCharacterName] = React.useState("");
    
    const handleKeyup = e => {
        if (e.key === "Enter") {
            handleAddCharacter();
        }
    }
    
    const handleAddCharacter = () => {
        // console.log("Add character", characterName);
        const regex = /[^a-z]|(and)/gm;
        const strippedName = characterName?.toLowerCase().replaceAll(regex, "");
    
        if (strippedName
            && smash[strippedName]
            && !chosenCharacters.includes(strippedName)
        ) {
            player.addCharacterString(strippedName);
            onUpdate(strippedName);
        }
        
        setCharacterName("");
    };
    
    const handleRemoveCharacter = (title) => () => {
        player.updateCharacters(title);
        onUpdate(title);
    }
    
    return (
        <Wrap>
            <h3><u><em>{player.username}</em></u></h3>
            <h5>Characters</h5>
            <ul>
                {player.characters.map((character, index) => (
                    <li key={index}>
                        {character.title}&nbsp;
                        <SvgBtn
                            onClick={handleRemoveCharacter(character.title)}
                        >
                            <AiFillCloseCircle/>
                        </SvgBtn>
                    </li>
                ))}
            </ul>
            <input
                type="text"
                placeholder="Character name"
                value={characterName}
                onChange={e => setCharacterName(e.target.value)}
                onKeyUp={handleKeyup}
            />
            <button onClick={handleAddCharacter}>Add</button>
        </Wrap>
    );
};
import React from "react";
import { useNavigate } from "react-router-dom";
import Location from "../Location";
import { Input } from "../FormInputs";
import { Btn, Btns, Card, Form } from "../Shared";
import styled from "styled-components";

const Error = styled(Card)`
  max-width: 400px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: var(--color);
  color: var(--light);
`;

const Join = () => {
  const navigate = useNavigate();
  const [form, setForm] = React.useState({});
  const [error, setError] = React.useState({});

  const isDisabled = () => !form.first || !form.last
    || !form.email // || !form.username
    || !form.password || !form.confirm
    || form.password !== form.confirm
    || form.email.indexOf("@") < 0;

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });
  const handleCancel = () => navigate("/");
  const handleJoin = async () => {
    // return alert("Not implemented yet");
    const response = await fetch("/api/user/create", {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form),
    })

    const json = await response.json();
    console.log(json);
    if (response.status !== 200) {
      setError(json);
    }
    else { 
      window.dan = json;
      navigate("/login");
    }
  }

  React.useEffect(() => {
    setForm({});
  }, [error])

  return (
    <>
      <Location title="Join Us" subtitle="Come Dan with us!"/>
      {error?.message && 
        <Error>
          {`Error: [${error?.name}] ${error?.message} - ${error?.description}`}
        </Error>
      }
      <Card>
        <Form>
          <Input
            onChange={handleChange}
            name="first"
          />
          <Input
            onChange={handleChange}
            name="last" 
          />
          <Input
            onChange={handleChange}
            name="email" 
          />
          <Input
            onChange={handleChange}
            name="password" 
            type="password"
          />
          <Input
            onChange={handleChange}
            name="confirm" 
            type="password"
          />
	  <Btns>
          <Btn
            value="join"
            onClick={handleJoin}
            disabled={isDisabled()}
          />
          <Btn
            value="later dude"
            onClick={handleCancel}
            // disabled={isDisabled()}
          />
	  </Btns>
        </Form>
      </Card>
    </>
  )
}

export default Join;
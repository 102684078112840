import styled from "styled-components";

export const Btn = styled.input.attrs({
  type: "button",
})`
  padding: ${({ padding }) => padding || "8px 24px"};
  border: solid 2px white;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 700;
  background-color: var(--color);
  color: white;
  cursor: pointer;
  //background: linear-gradient(to right, red, orange, yellow, green, blue, purple);
  //background-color: rgba(0, 0, 0, 0);
  //background-clip: border-box;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;

  &:hover {
    background-color: white;
    color: var(--color);
    border: solid 2px var(--color);
  }
  &:disabled {
    border-color: var(--dark);
    background-color: var(--grey);
    color: var(--dark);
    cursor: not-allowed;
  }
`;

export const Btns = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;

  & > [type=button] {
    margin-left: 8px;
  }
`;

export const Card = styled.div`
  margin: 0 auto;
  max-width: min(94%, 480px);
  padding: ${({ padding }) => padding ?? "32px"};
  border-radius: 16px;
  background-color: ${({ bgColor }) => bgColor ?? "var(--light)"};
  box-shadow: var(--shadow-elevation-medium);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > div:not(:first-of-type) {
    margin-top: 16px;
  }
`;
export class Match {
  constructor(slot1, slot2) {
    this.slot1 = slot1;
    this.slot2 = slot2;
    this.bye = slot2 ? false : true;
  }

  get versus() {
    return this.bye 
      ? `BYE for ${this.slot1.player.username}` 
      : `${this.slot1.character.title} vs. ${this.slot2?.character.title}`
  }
}
const main = "http://smashbros-ultimate.com/images/char/";

export const smash = {
    yoshi: `${main}yoshi.png`,
    mario: `${main}mario.png`,
    luigi: `${main}luigi.png`,
    peach: `${main}peach.png`,
    bowser: `${main}bowser.png`,
    samus: `${main}samus.png`,
    pikachu: `${main}pikachu.png`,
    fox: `${main}fox.png`,
    falco: `${main}falco.png`,
    marth: `${main}marth.png`,
    sheik: `${main}sheik.png`,
    ness: `${main}ness.png`,
    iceclimbers: `${main}ice_climbers.png`,
    kirby: `${main}kirby.png`,
    link: `${main}link.png`,
    younglink: `${main}young_link.png`,
    ganondorf: `${main}ganondorf.png`,
    mewtwo: `${main}mewtwo.png`,
    roy: `${main}roy.png`,
    pichu: `${main}pichu.png`,
    jigglypuff: `${main}jigglypuff.png`,
    donkeykong: `${main}donkey_kong.png`,
    drmario: `${main}dr_mario.png`,
    mrgamewatch: `${main}mr_game_and_watch.png`,
    captainfalcon: `${main}captain_falcon.png`,
    kingdedede: `${main}king_dedede.png`,
    lucario: `${main}lucario.png`,
    rob: `${main}rob.png`,
    toonlink: `${main}toon_link.png`,
    wolf: `${main}wolf.png`,
    snake: `${main}snake.png`,
    kingkrool: `${main}king_k_rool.png`,
    simon: `${main}simon.png`,
    richter: `${main}richter.png`,
    isabelle: `${main}isabelle.png`,
    incineroar: `${main}incineroar.png`,
    ken: `${main}ken.png`,
    pokemontrainer: `${main}pokemon_trainer.png`,
    darksamus: `${main}dark_samus.png`,
    chrom: `${main}chrom.png`,
    ridley: `${main}ridley.png`,
    darkpit: `${main}dark_pit.png`,
    lucina: `${main}lucina.png`,
    palutena: `${main}palutena.png`,
    pacman: `${main}pac-man.png`,
    shulk: `${main}shulk.png`,
    bowserjr: `${main}bowser_jr.png`,
    duckhunt: `${main}duck_hunt.png`,
    ryu: `${main}ryu.png`,
    zelda: `${main}zelda.png`,
    littlemac: `${main}little_mac.png`,
    daisy: `${main}daisy.png`,
    lucas: `${main}lucas.png`,
    sonic: `${main}sonic.png`,
    megaman: `${main}mega_man.png`,
    cloud: `${main}cloud.png`,
    corrin: `${main}corrin.png`,
    bayonetta: `${main}bayonetta.png`,
    inkling: `${main}inkling.png`,
    wario: `${main}wario.png`,
    piranhaplant: `${main}piranha_plant.png`,
    joker: `${main}joker.png`,
    hero: `${main}hero.png`,
    banjokazooie: `${main}banjo-and-kazooie.png`,
    terry: `${main}terry_bogard.png`,
    byleth: `${main}byleth.png`,
    minmin: `${main}min_min.png`,
    steve: `${main}steve.png`,
    sephiroth: `${main}sephiroth.png`,
    pyramythra: `https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fgamingreinvented.com%2Fwp-content%2Fuploads%2F2021%2F02%2FPyraandMythra-1536x1102.png&f=1&nofb=1&ipt=c9528ab0f43c74c32ab0714e434f7e3d2cfc74e78ce35564a16e3b3c8de2c277&ipo=images`,
    olimar: `${main}olimar.png`,
    metaknight: `${main}meta_knight.png`,
    diddykong: `${main}diddy_kong.png`,
    ike: `${main}ike.png`,
    wiifittrainer: `${main}wii_fit_trainer.png`,
    villager: `${main}villager.png`,
    robin: `${main}robin.png`,
    rosalinaluma: `${main}rosalina.png`,
    greninja: `${main}greninja.png`,
    sora: `https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fartfiles.alphacoders.com%2F148%2Fthumb-1920-148199.png&f=1&nofb=1&ipt=845e6e6f5ac98eb61cabd4c108d42b12613db97e6b9572ff95ed592ff8fbbab3&ipo=images`,
    zerosuitsamus: `${main}zero_suit_samus.png`,
    kazuya: `https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.pidgi.net%2Fwiki%2Fimages%2F9%2F9b%2FKazuya_(alt)_-_Super_Smash_Bros_Ultimate.png&f=1&nofb=1&ipt=af2a89e13f0f5fb81cd50e31c7f2cc1bd6352d4d52bf6f14f260f873998a4e0d&ipo=images`,
    pit: `${main}pit.png`,
    miifighter: `https://vignette.wikia.nocookie.net/ssbb/images/1/1d/Karateka_Mii_SSBU.png/revision/latest?cb=20180616224634&path-prefix=es`,
};
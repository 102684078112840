import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../smashLogo.png";
import Location from "../Location";

const Wrap = styled.main`
  width: 100%;
  padding: 32px 0;
  
  img {
    margin: 0 auto;
  }
  p {
    padding: 16px 0;
    text-align: center;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Logo = styled.img`
    animation: ${rotate} infinite 30s linear;
`;

const Welcome = styled.h2`
    text-align: center;
`;

const Always = styled.p`
    width: 100%;
    text-align: center;
    position: absolute;
    top: 69vh;
`;

// const getSmashImage = (name) => {
//   const url = "http://smashbros-ultimate.com/images/char/";
//   if (name === "Banjo & Kazooie") return `${url}banjo-and-kazooie.png`;
//   return url + name.replaceAll(" ", "_").toLocaleLowerCase() + ".png";
// }

const Home = () => {
    const jsonStart = document.cookie.indexOf("{");
    if (jsonStart >= 0) window.dan = JSON.parse(document.cookie.slice(jsonStart));
    
    return (
        <Wrap>
            <Location title="The CitaDan" subtitle="The home of the Dan's"/>
            <Logo src={logo} alt="logo" />
            {/*<Logo src={getSmashImage("Captain Falcon")} alt="logo" />*/}
            <Always>It's always been the six of us.</Always>
            {window?.dan?.first && <Welcome>Welcome {window.dan.first} {window.dan.last}!</Welcome>}
        </Wrap>
  )
}

export default Home;
import React from "react";
import styled from "styled-components";
import * as Classes from "../classes";
import { Rounds } from "./";

const danTourney = new Classes.Tournament(6);

const nick = new Classes.Player("annemarie");
const john = new Classes.Player("CaVs");
const dan = new Classes.Player("DankÉ");
const gage = new Classes.Player("gag");
const matt = new Classes.Player("HennY II");
const joe = new Classes.Player("hOtSaUcE");

const participants = [nick, john, dan, gage, matt, joe];
participants.forEach(p => danTourney.addParticipant(p));

const InputWrap = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: baseline;

  & input {
    margin-left: 8px;
    flex-grow: 1;
  }
`;

const Submit = styled.button`
  margin-top: 8px;
  padding: 8px;
  border: 2px solid #333;
  border-radius: 4px;
  color: #333;

  &:hover {
    border: 2px solid #2FF924;
  }

  &:focus {
    border: 2px solid #2FF924;
  }

  &:disabled {
    background-color: #DDD;
    border-color: #AAA;
    color: #AAA;
    cursor: initial;
  }
`;

function TourneyForm({ setTourney }) {
    const [characters, setCharacters] = React.useState({});
    // const [submitDisabled, setSubmitDisabled] = React.useState(false); // not being utilized
    
    const handleSubmitClick = () => {
        nick.addCharacterString(characters.nick);
        john.addCharacterString(characters.john);
        dan.addCharacterString(characters.dan);
        gage.addCharacterString(characters.gage);
        matt.addCharacterString(characters.matt);
        joe.addCharacterString(characters.joe);
        danTourney.matchRandomizer();
        setTourney(danTourney);
    }
    
    /*
    // need useEffect to change submitDisabled
    React.useEffect(() => {
      if (
        characters.nick
        && characters.john
        && characters.dan
        && characters.gage
        && characters.matt
        && characters.joe
      ) {
        setSubmitDisabled(false);
      }
      else {
        setSubmitDisabled(true);
      }
    }, [characters])
    */
    
    return (
        <>
            <h1>Welcome Dans!</h1>
            <h4>Enter your characters here:</h4>
            <InputWrap>
                <label htmlFor="nick">annemarie:</label>
                <input
                    type="text"
                    name="nick"
                    onChange={(e) => setCharacters({...characters, nick: e.target.value})}
                />
            </InputWrap>
            <InputWrap>
                <label htmlFor="john">CaVs:</label>
                <input
                    type="text"
                    name="john"
                    onChange={(e) => setCharacters({...characters, john: e.target.value})}
                />
            </InputWrap>
            <InputWrap>
                <label htmlFor="dan">DankÉ:</label>
                <input
                    type="text"
                    name="dan"
                    onChange={(e) => setCharacters({...characters, dan: e.target.value})}
                />
            </InputWrap>
            <InputWrap>
                <label htmlFor="gage">gag:</label>
                <input
                    type="text"
                    name="gage"
                    onChange={(e) => setCharacters({...characters, gage: e.target.value})}
                />
            </InputWrap>
            <InputWrap>
                <label htmlFor="matt">HennY II:</label>
                <input
                    type="text"
                    name="matt"
                    onChange={(e) => setCharacters({...characters, matt: e.target.value})}
                />
            </InputWrap>
            <InputWrap>
                <label htmlFor="joe">hOtSaUcE:</label>
                <input
                    type="text"
                    name="joe"
                    onChange={(e) => setCharacters({...characters, joe: e.target.value})}
                />
            </InputWrap>
            <Submit
                // disabled={submitDisabled}
                onClick={handleSubmitClick}
            >Submit</Submit>
        </>
    )
}

const Wrap = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormWrap = styled.div`
  min-width: 50vw;
  min-height: 50vh;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: var(--SHADOW-FULL);
`;

export function QuickTourney() {
    const [tourney, setTourney] = React.useState(null);
    
    return (
        <Wrap>
            {
                Boolean(tourney)
                    ? <Rounds tournament={tourney}/>
                    : (
                        <FormWrap>
                            <TourneyForm setTourney={setTourney}/>
                        </FormWrap>
                    )
            }
        </Wrap>
    )
}